.login-container {
    margin-top: 10%;
}

.hidden {
    display: none;
}

.error-alert {
    margin-top: 20px;
}