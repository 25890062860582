body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.login-container{margin-top:10%}.hidden{display:none}.error-alert{margin-top:20px}

.user-body{margin-top:100px}.center-table{margin-left:auto;margin-right:auto}.users-title{display:inline-block}.add-button{float:right}.delete-buttons{float:right}.yes-button{margin-right:20px}

.page-buttons{float:right}.prev-btn{margin-right:5px}.languages{float:right}.languages .radio{float:left;margin-right:10px}.add-project-btn{float:right;margin-left:10px}.date-end{float:left;display:inline-block;margin-bottom:20px}.date-start{display:inline-block;margin-right:20px;float:left;margin-bottom:20px}#filesubmit{margin-bottom:20px}.float-inline{float:left;display:inline-block;margin-bottom:20px}.clear-alert{clear:both}button.MuiButtonBase-root.MuiIconButton-root{padding:0px}

#password-setup{margin-top:10%;width:40%}

#password-set{margin-top:10%;text-align:center}#goLoginButton{display:block;margin-top:20px;margin-left:auto;margin-right:auto}.confirmation-card{padding:20px}

.thumbnail{max-width:100px}

.hr-info{margin-top:100px}.table td{vertical-align:middle !important}.table-actions{width:15%}.alert{margin-top:2rem}.date-only{margin-bottom:20px}

